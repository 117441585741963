import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { LocationOrHref, useRoutePropagation } from '@shopify/app-bridge-react'
import NewProductsRulePage from '~/pages/embedded/products-rule'
import Index from '~/pages/embedded'
import ProductRulePage from '~/pages/embedded/products-rule/[id]'
import ProductCtfEntriesList from '~/pages/embedded/ctf-entries'

const PageStructure = (props: { location: LocationOrHref }) => {
  const { location } = props
  useRoutePropagation(location)
  return (
    <>
      <Switch>
        <Route path="/embedded/products-rule" exact>
          <NewProductsRulePage />
        </Route>
        <Route path="/embedded/products-rule/:id">
          <ProductRulePage />
        </Route>
        <Route path="/embedded" exact>
          <Index />
        </Route>
        <Route path="/embedded/ctf-entries">
          <ProductCtfEntriesList />
        </Route>
      </Switch>
    </>
  )
}

export default withRouter(PageStructure)
