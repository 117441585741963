import { ContentfulManagement } from '~/services/contentful'
import DynamoDB from '~/services/dynamodb'

export const ServiceContainer = (function () {
  let ctfManagement: ContentfulManagement
  let dynamoDbClient: DynamoDB

  function createCtfManagementInstance(apiKey: string) {
    return new ContentfulManagement(apiKey)
  }

  function createDynamoDBInstance() {
    return new DynamoDB()
  }

  return {
    ctfManagement: function (apiKey?: string) {
      if (!ctfManagement) {
        ctfManagement = createCtfManagementInstance(apiKey ?? '')
        return ctfManagement
      }
      return ctfManagement
    },
    dynamoDb: function () {
      if (!dynamoDbClient) {
        dynamoDbClient = createDynamoDBInstance()
        return dynamoDbClient
      }
      return dynamoDbClient
    },
  }
})()
