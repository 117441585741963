/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@shopify/app-bridge-react'
import { Page, Layout, Card, DescriptionList, Banner } from '@shopify/polaris'
import { useRouter } from 'next/router'
import React, { useContext, useRef, useState } from 'react'
import { SessionContext } from '~/components/SessionProvider'
import { ServiceContainer } from '~/services'
import { RuleItem } from '~/services/dynamodb/stores'
import { ConnectionContext } from '../RuleForm/Connection'
import { ConnectionDetailsForm } from '../RuleForm/ConnectionDetails'
import { ConnectionRulesForm } from '../RuleForm/ConnectionRules'
import { FormRule } from './NewProductRule'

export const ProductRule = (props: { rule: RuleItem | undefined }) => {
  const router = useRouter()
  const { endpoint, config } = useContext(SessionContext)
  const [editMode, setEditMode] = useState<boolean>(false)

  const {
    values,
    reset,
    webhookId,
    titleTemplate,
    descriptionTemplate,
    setTitleTemplate,
    setDescriptionTemplate,
  } = useContext(ConnectionContext)

  const updateTemapltes = () => {
    setTitleTemplate(values?.mappings?.find((mapping) => mapping.key === 'Title')?.template ?? '')
    setDescriptionTemplate(values?.mappings?.find((mapping) => mapping.key === 'Description')?.template ?? '')
  }

  const checkValidForm = (rule?: FormRule) => {
    if (rule?.mappings.length === 0 || !rule?.content_model_id || !rule?.rule_name || !rule?.shopify_ref_field)
      return false
    return true
  }

  const successTimout = useRef<NodeJS.Timeout>()
  const errorTimeout = useRef<NodeJS.Timeout>()

  const [success, setSuccess] = useState<boolean>(false)
  const [invalid, setInvalid] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  const handleSave = async () => {
    if (!endpoint || !props.rule) return
    setSaving(true)
    const valid = checkValidForm(values)
    if (!valid) {
      setInvalid(true)
      setErrorMessage(
        'Please enter rule name, content model, shopify reference field and at least one rule definition.',
      )
      !!errorTimeout.current && clearTimeout(errorTimeout.current)
      errorTimeout.current = setTimeout(() => {
        setInvalid(false)
      }, 3000)
      setSaving(false)
      return
    }
    await ServiceContainer.ctfManagement(config.management_api_key).updateWebhook(webhookId ?? '', {
      name: values?.rule_name,
      contentTypeId: values?.content_model_id,
    })
    const url = new URL(`${window.location.origin}/api/stores/rules`)
    const mappings = values?.mappings.map((mapping) => {
      switch (mapping.key) {
        case 'Title':
          return { ...mapping, template: titleTemplate }
        case 'Description':
          return { ...mapping, template: descriptionTemplate }
        default:
          return mapping
      }
    })
    const response = await fetch(url.toString(), {
      method: 'PUT',
      body: JSON.stringify({
        endpoint,
        ...values,
        mappings,
        rule_id: props.rule.data_variant.split('#')[1],
      }),
    })
    if (response.status === 200) {
      setSuccess(true)
      !!successTimout.current && clearTimeout(successTimout.current)
      successTimout.current = setTimeout(() => {
        setSuccess(false)
        router.back()
      }, 3000)
      setEditMode(false)
      setSaving(false)
      return
    }
    setInvalid(true)
    setErrorMessage('Something went wrong.')
    !!errorTimeout.current && clearTimeout(errorTimeout.current)
    errorTimeout.current = setTimeout(() => {
      setInvalid(false)
    }, 3000)
    setSaving(false)
  }

  const handleDelete = async () => {
    if (!endpoint) return
    setDeleting(true)
    const url = new URL(`${window.location.origin}/api/stores/rules/delete`)
    const response = await fetch(url.toString(), { method: 'POST', body: JSON.stringify(props.rule) })
    if (response.status === 200) {
      try {
        await ServiceContainer.ctfManagement(config.management_api_key).deleteWebhook(webhookId ?? '')
        router.back()
        setDeleting(false)
        return
      } catch (error) {
        setErrorMessage(
          'Contentful webhook could not be deleted. You might need to do it manually. Navigate to your contentful workspace and delete the webhook.',
        )
        setInvalid(true)
        setDeleting(false)
        !!errorTimeout.current && clearTimeout(errorTimeout.current)
        errorTimeout.current = setTimeout(() => {
          setInvalid(false)
        }, 3000)
        return
      }
    } else {
      setInvalid(true)
      setDeleting(false)
      setErrorMessage('Something went wrong.')
      !!errorTimeout.current && clearTimeout(errorTimeout.current)
      errorTimeout.current = setTimeout(() => {
        setInvalid(false)
      }, 3000)
      return
    }
  }

  return (
    <Page
      title={'Synchronization rule'}
      titleMetadata={
        <Banner status={values?.active ? 'success' : 'warning'}>{values?.active ? 'Active' : 'Inactive'}</Banner>
      }
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: !editMode,
          onAction: () => {
            reset()
            setEditMode(false)
          },
        },
        {
          content: 'Edit',
          disabled: editMode,
          onAction: () => {
            updateTemapltes()
            setEditMode(true)
          },
        },
      ]}
      pagination={{ hasPrevious: true, hasNext: false, onPrevious: () => router.back() }}
      divider={true}
    >
      <Layout>
        {invalid && (
          <Layout.Section>
            <Banner status="critical" title={errorMessage} onDismiss={() => setInvalid(false)} />
          </Layout.Section>
        )}
        {success && (
          <Layout.Section>
            <Banner
              status="success"
              title="The rule has been successfully updated."
              onDismiss={() => setSuccess(false)}
            />
          </Layout.Section>
        )}
        <Layout.Section>
          <Card
            primaryFooterAction={{ content: 'Save', disabled: !editMode, onAction: handleSave, loading: saving }}
            secondaryFooterActions={[
              {
                content: 'Delete',
                onAction: () => setDeleteModalOpen(true),
                destructive: true,
              },
            ]}
            actions={[{ content: 'To home page', url: '/embedded', accessibilityLabel: 'Navigate to home page.' }]}
          >
            <Card.Section title="Connection details">
              {editMode ? (
                <ConnectionDetailsForm />
              ) : (
                <DescriptionList
                  items={[
                    { term: 'Rule name', description: values?.rule_name },
                    { term: 'Content model', description: values?.content_model_id },
                    { term: 'Shopify reference field', description: values?.shopify_ref_field },
                  ]}
                />
              )}
            </Card.Section>
            <Card.Section title="Connection rules">
              {editMode ? (
                <ConnectionRulesForm />
              ) : (
                <DescriptionList
                  items={
                    values?.mappings?.map((mapping) => ({
                      term: `${mapping.key} template`,
                      description: mapping.template,
                    })) ?? []
                  }
                />
              )}
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          title={'Delete synchronization rule?'}
          primaryAction={{ content: 'Delete rule', onAction: handleDelete, destructive: true, loading: deleting }}
          secondaryActions={[{ content: 'Cancel', onAction: () => setDeleteModalOpen(false) }]}
          message={'This cannot be undone.'}
        ></Modal>
      </div>
    </Page>
  )
}
