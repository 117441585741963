import useFormFields, { Bindings, SetValueFn } from 'lib/hooks/useFormFields'
import { mergeDeepRight } from 'ramda'
import React, { createContext, useMemo } from 'react'

interface FormValues {
  space_id: string
  management_api_key: string
  environment_id: string
}
interface ConfigContextProps {
  bindings: Bindings<FormValues> | undefined
  values: FormValues | undefined
  setValue: SetValueFn<FormValues>
  reset: () => void
}

const initialFormValues: FormValues = {
  space_id: '',
  management_api_key: '',
  environment_id: '',
}

export const ConfigContext = createContext<ConfigContextProps>({
  bindings: undefined,
  values: undefined,
  setValue: () => {},
  reset: () => {},
})

export const ConfigContextProvider: React.FC<{ initialValues: Optional<FormValues> }> = (props) => {
  const initialValues = useMemo(() => mergeDeepRight(initialFormValues, props.initialValues), [
    props.initialValues,
  ]) as FormValues
  const { bindings, values, setValue, reset } = useFormFields(initialValues, { resetOnInitialValueChange: true })
  const value = useMemo(
    () => ({
      bindings,
      values,
      reset,
      setValue,
    }),
    [bindings, reset, setValue, values],
  )
  return <ConfigContext.Provider value={value}>{props.children}</ConfigContext.Provider>
}
