import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'local'

Sentry.init({
  dsn: SENTRY_DSN || 'https://dcdb871915084a61b4e9f0386228e79c@o323014.ingest.sentry.io/6363573',
  environment: SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
  debug: false,
  autoSessionTracking: true,
})
