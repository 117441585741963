import { ResourcePicker, useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { SelectPayload } from '@shopify/app-bridge/actions/ResourcePicker'
import { Layout, Banner, Button, EmptyState, Card, DescriptionList } from '@shopify/polaris'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { SessionContext } from '~/components/SessionProvider'
import { ServiceContainer } from '~/services'
import { ConfigItem, RuleItem } from '~/services/dynamodb/stores'
import { RulesList } from '../RulesList'
import { ConfigContext } from './ConfigForm'
import { ConfigForm } from './ConfigForm/form'

interface ContentfulConfigProps {
  emptyState: boolean
  setEmptyState: React.Dispatch<React.SetStateAction<boolean>>
  rules: RuleItem[]
  setRules: React.Dispatch<React.SetStateAction<RuleItem[]>>
  rulesLoading: boolean
}

export const ContentfulConfig = (props: ContentfulConfigProps) => {
  const { emptyState, setEmptyState, rules, setRules } = props
  const imageUrl = 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'
  const { endpoint, setConfig } = useContext(SessionContext)
  const app = useAppBridge()

  const [invalidConfig, setInvalidConfig] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [errorTitle, setErrorTitle] = useState<string>('')
  const [openResourcePicker, setOpenResourcePicker] = useState<boolean>(false)

  const { values, reset } = useContext(ConfigContext)

  const errorTimeout = useRef<NodeJS.Timeout>()
  const successTimout = useRef<NodeJS.Timeout>()

  const checkCtfConfig = async (config: Omit<ConfigItem, 'data_variant' | 'endpoint'> | undefined) => {
    try {
      const ctfManagement = ServiceContainer.ctfManagement(config?.management_api_key)
      await ctfManagement.initialize(config?.space_id ?? '', config?.environment_id ?? 'master')
    } catch (error) {
      setErrorTitle('Please enter valid Space Id, Api key and Environment.')
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    if (!endpoint) return
    setLoading(true)
    const valid = await checkCtfConfig(values)
    if (valid) {
      const url = new URL(`${window.location.origin}/api/stores/config`)
      const response = await fetch(url.toString(), {
        method: 'POST',
        body: JSON.stringify({
          ...values,
          endpoint,
          environment_id: !!values?.environment_id ? values.environment_id : 'master',
        }),
      })
      if (response.status === 200) {
        setConfig({
          environment_id: values?.environment_id ?? 'master',
          space_id: values?.space_id ?? '',
          management_api_key: values?.management_api_key ?? '',
        })
        setEmptyState(false)
        setLoading(false)
        setSuccess(true)
        !!successTimout.current && clearTimeout(successTimout.current)
        successTimout.current = setTimeout(() => {
          setSuccess(false)
        }, 3000)
        setEditMode(false)
        return
      }
    }
    setInvalidConfig(true)
    !!errorTimeout.current && clearTimeout(errorTimeout.current)
    errorTimeout.current = setTimeout(() => {
      setInvalidConfig(false)
    }, 3000)
    setLoading(false)
    reset()
  }

  const fetchRules = useCallback(async () => {
    if (!endpoint) return
    const url = new URL(`${window.location.origin}/api/stores/rules`)
    url.searchParams.append('endpoint', endpoint as string)
    const response = await fetch(url.toString())
    if (response.status === 200) {
      const rules = (await response.json()) as RuleItem[]
      setRules(rules)
    }
  }, [endpoint, setRules])

  const handleSelection = (resources: SelectPayload) => {
    if (!endpoint) return
    const idArray = resources.selection[0].id.split('/')
    const id = idArray[idArray.length - 1]
    setOpenResourcePicker(false)
    const redirect = Redirect.create(app)
    redirect.dispatch(Redirect.Action.APP, `/embedded/ctf-entries?id=${id}&shop=${endpoint}`)
  }

  return (
    <>
      {invalidConfig && (
        <Layout.Section>
          <Banner status="critical" title={errorTitle} onDismiss={() => setInvalidConfig(false)} />
        </Layout.Section>
      )}
      {success && (
        <Layout.Section>
          <Banner
            status="success"
            title="The configuration has been successfully saved."
            onDismiss={() => setSuccess(false)}
          />
        </Layout.Section>
      )}
      {emptyState && (
        <Layout.Section>
          <EmptyState heading="Configure Contentful Connection" image={imageUrl}>
            <p style={{ paddingBottom: '1rem' }}>
              Configure Contentful Space Id to synchronize marketing purpose product informations between headless CMS
              and Shopify for higher flexibility.
            </p>
            <ConfigForm />
            <div style={{ paddingTop: '2rem' }}></div>
            <Button primary loading={loading} onClick={handleSubmit}>
              Configure
            </Button>
          </EmptyState>
        </Layout.Section>
      )}
      {!emptyState && (
        <>
          <Layout.AnnotatedSection
            title="Contentful Configuration Details"
            description="The space Id, Management Api Key and Environment Id will be used to establish the connection between the CMS and your store."
          >
            <Card
              sectioned
              actions={[{ content: 'Edit', disabled: editMode, onAction: () => setEditMode(true) }]}
              primaryFooterAction={{ content: 'Save', onAction: handleSubmit, loading, disabled: !editMode }}
              secondaryFooterActions={[
                {
                  content: 'Cancel',
                  disabled: !editMode,
                  onAction: () => {
                    reset()
                    setEditMode(false)
                  },
                },
              ]}
            >
              {editMode ? (
                <ConfigForm />
              ) : (
                <DescriptionList
                  items={[
                    { term: 'Contentful Space Id', description: values?.space_id },
                    {
                      term: 'Contentful Management API Key',
                      description: `${values?.management_api_key.substring(0, 5)}...`,
                    },
                    { term: 'Contentful Environment', description: values?.environment_id },
                  ]}
                />
              )}
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Synchronization Rules"
            description="You set up one or more connections between Products in your store and Content Models in your Contentful Space"
          >
            <Card>
              <Card.Header
                actions={[{ content: 'New rule', url: '/embedded/products-rule' }]}
                title={'Products Synchronization Rules'}
              ></Card.Header>
              <Card.Section>
                <RulesList loading={props.rulesLoading} type="products" rules={rules} refetchRules={fetchRules} />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Contentful connections"
            description="You can select a shopify product and look up what contentful entries it is connected to."
          >
            <Card>
              <Card.Header title="Connections" />
              <Card.Section>
                <Button onClick={() => setOpenResourcePicker(true)} primary>
                  Select a product
                </Button>
                <ResourcePicker
                  resourceType="Product"
                  showVariants={false}
                  open={openResourcePicker}
                  onSelection={(resources) => handleSelection(resources)}
                  allowMultiple={false}
                  onCancel={() => setOpenResourcePicker(false)}
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </>
      )}
    </>
  )
}
