import ApolloClient from "apollo-boost";
import { ApolloProvider as Provider } from "react-apollo";
import { useAppBridge } from "@shopify/app-bridge-react";
import { fetch } from "~/services/shopify/app-bridge";

export const ApolloProvider: React.FC = (props) => {
  const app = useAppBridge();
  const client = new ApolloClient({
    fetch: fetch(app),
    fetchOptions: {
      credentials: "include",
    },
  });

  return <Provider client={client}>{props.children}</Provider>;
}
