import { useEffect, useContext } from 'react'
import Router, { useRouter } from 'next/router'
import { Context as AppBridgeContext } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { RoutePropagator as ShopifyRoutePropagator } from '@shopify/app-bridge-react'

export const RoutePropagator = () => {
  const { route } = useRouter()

  const appBridge = useContext(AppBridgeContext)

  useEffect(() => {
    appBridge?.subscribe(Redirect.Action.APP, (payload) => {
      Router.push(payload.path)
    })
  }, [appBridge])

  if (!appBridge || !route) return null

  return <ShopifyRoutePropagator location={route} />
}
