import '@shopify/polaris/dist/styles.css'
import React from 'react'
import { useRouter } from 'next/router'
import { AppProps } from 'next/app'
import { EmbeddedApp } from '~/components/EmbeddedApp'
import AppRouter from '~/components/AppRouter'
import PageStructure from '~/components/PageStructure'

const App = (props: AppProps) => {
  const { Component, pageProps } = props
  const { pathname } = useRouter()
  const isEmbedded = pathname.startsWith('/embedded')
  return (
    <>
      {isEmbedded ? (
        <EmbeddedApp>
          <AppRouter />
          <PageStructure />
        </EmbeddedApp>
      ) : (
        <Component {...pageProps} />
      )}
    </>
  )
}

export default App
