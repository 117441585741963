import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import React from "react";
import { Link } from "../Link";

export const PolarisProvider: React.FC = (props) => {
  return (
    <AppProvider i18n={translations} linkComponent={Link}>
      {props.children}
    </AppProvider>
  );
};
