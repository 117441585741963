import React, { useContext } from 'react'
import { FormLayout, TextField, Link } from '@shopify/polaris'
import { ConfigContext } from '.'

export const ConfigForm = () => {
  const { bindings, setValue } = useContext(ConfigContext)
  return (
    <FormLayout>
      <TextField
        {...bindings?.space_id}
        onChange={(value) => setValue('space_id', value)}
        label="Contenful Space Id"
        inputMode="text"
        id="contentfulSpaceId"
        clearButton
        onClearButtonClick={() => setValue('space_id', '')}
      />
      <TextField
        {...bindings?.management_api_key}
        onChange={(value) => setValue('management_api_key', value)}
        label="Contenful Management API Key"
        inputMode="text"
        id="contentfulManagementAPIKey"
        clearButton
        onClearButtonClick={() => setValue('management_api_key', '')}
        helpText={
          <span>
            Learn more about{' '}
            <Link
              external
              url="https://www.contentful.com/help/personal-access-tokens/#how-to-get-a-personal-access-token-the-web-app"
            >
              Contentful Management API Key
            </Link>
          </span>
        }
      />
      <TextField
        {...bindings?.environment_id}
        onChange={(value) => setValue('environment_id', value)}
        label="Contenful Environment"
        inputMode="text"
        id="contentfulEnvironment"
        clearButton
        onClearButtonClick={() => setValue('environment_id', '')}
        helpText={<span>If left empty, the master environment will be used per default.</span>}
      />
    </FormLayout>
  )
}
