import { Stack, TextContainer, Popover, Button, OptionList, Tag } from '@shopify/polaris'
import React, { useContext, useState } from 'react'
import { ConnectionContext } from '~/containers/RuleForm/Connection'

interface MappingMediaFieldProps {
  mappingKey: string
  onChange: (selected: string[]) => void
  onRemove: (fieldIdx: number) => void
  options: {
    label: string
    value: string
  }[]
  idx: number
  chosenOptions: string[]
}

export const MappingMediaField = (props: MappingMediaFieldProps) => {
  const { values } = useContext(ConnectionContext)
  const splitTemplateInArray = (template: string) => {
    const regExp = /[^{{{\}]+(?=}}})/g
    return template.match(regExp)
  }

  const [mediaPopoverActive, setMediaPopoverActive] = useState<boolean>(false)

  return (
    <div>
      <Stack alignment="trailing">
        <Stack.Item>
          <TextContainer>Choose media fields</TextContainer>
          <div style={{ height: 4 }}></div>
          <Popover
            active={mediaPopoverActive}
            activator={
              <Button
                disclosure
                onClick={() => setMediaPopoverActive((prev) => !prev)}
              >{`${props.mappingKey} fields`}</Button>
            }
            onClose={() => setMediaPopoverActive(false)}
          >
            <OptionList
              title={`Select fields for product's ${props.mappingKey}`}
              onChange={props.onChange}
              options={props.options}
              allowMultiple
              selected={props.chosenOptions}
            />
          </Popover>
        </Stack.Item>
        <Stack.Item>
          <Stack spacing="tight">
            {splitTemplateInArray(values?.mappings?.[props.idx].template ?? '')?.map((field, fieldIdx) => (
              <Tag key={`media-field-${field}`} onRemove={() => props.onRemove(fieldIdx)}>
                {field}
              </Tag>
            ))}
          </Stack>
        </Stack.Item>
      </Stack>
    </div>
  )
}
