import React, { useContext } from 'react'
import { Connection } from '~/containers/RuleForm/Connection'
import { NewProductRule } from '~/containers/ProductRule/NewProductRule'
import { SessionContext } from '~/components/SessionProvider'
import { Frame, Loading } from '@shopify/polaris'

const NewProductsRulePage = () => {
  const { initialized } = useContext(SessionContext)

  if (!initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Connection initialValues={{}}>
      <NewProductRule />
    </Connection>
  )
}

export default NewProductsRulePage
