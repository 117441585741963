import { Layout, Page, Frame, Loading } from '@shopify/polaris'
import { RuleItem } from '~/services/dynamodb/stores'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ConfigContextProvider } from '~/containers/ContentfulConfig/ConfigForm'
import { ContentfulConfig } from '~/containers/ContentfulConfig'
import { SessionContext } from '~/components/SessionProvider'

const Index = () => {
  const { endpoint, config, initialized } = useContext(SessionContext)
  const [emptyState, setEmptyState] = useState(true)
  const [rules, setRules] = useState<RuleItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const checkCtfConfig = useCallback(async () => {
    return !!config.space_id && !!config.environment_id && !!config.management_api_key
  }, [config])

  const fetchRules = useCallback(async () => {
    if (!endpoint) return
    setLoading(true)
    const url = new URL(`${window.location.origin}/api/stores/rules`)
    url.searchParams.append('endpoint', endpoint as string)
    const response = await fetch(url.toString())
    if (response.status === 200) {
      const rules = (await response.json()) as RuleItem[]
      setRules(rules)
      setLoading(false)
    }
    setLoading(false)
  }, [endpoint])

  const initialize = useCallback(async () => {
    const valid = await checkCtfConfig()
    if (valid) {
      setEmptyState(false)
      await fetchRules()
    }
  }, [checkCtfConfig, fetchRules])

  useEffect(() => {
    initialize()
  }, [initialize])

  if (!initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Page>
      <ConfigContextProvider initialValues={config ?? {}}>
        <Layout>
          <ContentfulConfig rulesLoading={loading} emptyState={emptyState} setEmptyState={setEmptyState} rules={rules} setRules={setRules} />
        </Layout>
      </ConfigContextProvider>
    </Page>
  )
}

export default Index
