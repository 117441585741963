import React, { createContext, useMemo, useState } from 'react'
import { ClientApplication } from '@shopify/app-bridge'
import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { useCallback, useEffect } from 'react'
import { captureException } from '@sentry/nextjs'
import { ConfigItem } from '~/services/dynamodb/stores'
import { ServiceContainer } from '~/services'

interface CtfConfig {
  space_id: string
  management_api_key: string
  environment_id: string
}

interface SessionContextProps {
  endpoint: string
  config: CtfConfig
  setConfig: React.Dispatch<React.SetStateAction<CtfConfig>>
  initialized: boolean
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>
}

export const SessionContext = createContext<SessionContextProps>({
  endpoint: '',
  config: {
    space_id: '',
    management_api_key: '',
    environment_id: '',
  },
  initialized: false,
  setInitialized: () => {},
  setConfig: () => {},
})

export const SessionProvider: React.FC = (props) => {
  const [prevEndpoint, setPrevEndpoint] = useState<string | null>()
  const [config, setConfig] = useState<CtfConfig>({ space_id: '', environment_id: '', management_api_key: '' })
  const [initialized, setInitialized] = useState<boolean>(false)
  const url = new URL(window.location.href)
  let endpoint = url.searchParams.get('shop')
  useSession(endpoint as string, setConfig, setInitialized)
  const value = useMemo(() => {
    if (!prevEndpoint) {
      setPrevEndpoint(endpoint)
    }
    return {
      endpoint: prevEndpoint ?? '',
      config,
      setConfig,
      initialized,
      setInitialized,
    }
  }, [config, endpoint, initialized, prevEndpoint])
  return <SessionContext.Provider value={value}>{props.children}</SessionContext.Provider>
}

export const useSession = (
  endpoint: string,
  setConfig: React.Dispatch<React.SetStateAction<CtfConfig>>,
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const app = useAppBridge()
  const getPermissions = (app: ClientApplication<any>, authRoute: string) => {
    if (window.top == window.self) {
      window.location.assign(authRoute)
    } else {
      const redirect = Redirect.create(app)
      redirect.dispatch(Redirect.Action.REMOTE, authRoute)
    }
  }

  const getSession = useCallback(async () => {
    if (!endpoint) return
    const sessionUrl = new URL(`${window.location.origin}/api/auth/session`)
    sessionUrl.searchParams.append('shop', endpoint as string)
    try {
      const sessionRequestReturn = await fetch(sessionUrl.toString())
      if (sessionRequestReturn.status === 200) {
        const sessionBody = await sessionRequestReturn.json()
        if (sessionBody.authRoute) {
          getPermissions(app, sessionBody.authRoute)
        }
      } else {
        captureException(sessionRequestReturn)
      }
    } catch (error) {
      captureException(error)
    }
  }, [app, endpoint])

  const getCtfApiKey = useCallback(async () => {
    if (!endpoint) return
    const url = new URL(`${window.location.origin}/api/stores/config`)
    url.searchParams.append('endpoint', endpoint as string)
    const response = await fetch(url.toString())
    if (response.status === 200) {
      const config = (await response.json()) as ConfigItem
      try {
        const ctfClient = await ServiceContainer.ctfManagement(config.management_api_key)
        await ctfClient.initialize(config.space_id, config.environment_id)
        setInitialized(true)
      } catch (error) {
        setInitialized(true)
        captureException(error)
      }
      setConfig({
        space_id: config.space_id,
        management_api_key: config.management_api_key,
        environment_id: config.environment_id,
      })
    }
    setInitialized(true)
  }, [endpoint, setConfig, setInitialized])

  useEffect(() => {
    getSession()
    getCtfApiKey()
  }, [getCtfApiKey, getSession])
}
