import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
  url: string
  external?: boolean
}

export const Link: React.FC<LinkProps> = (props) => {
  const { external, url, ref, ...rest } = props
  if (external) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>
        {props.children}
      </a>
    )
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {props.children}
    </ReactRouterLink>
  )
}
