import { Label, Select } from '@shopify/polaris'
import Tags from '@yaireo/tagify/dist/react.tagify' // React-wrapper file
import '@yaireo/tagify/dist/tagify.css' // Tagify CSS
import { useRef } from 'react'

interface MappingTextfieldProps {
  mappingKey: string
  value?: string
  options: {
    label: string
    value: string
  }[]
  onSelect: (value: string) => void
  onChange: (event: CustomEvent<Tagify.ChangeEventData<Tagify.TagData>>) => void
  idx?: number
}

export const MappingTextfield = (props: MappingTextfieldProps) => {
  const { idx = 0 } = props

  const tagifyRef = useRef<Tagify<Tagify.TagData>>()
  return (
    <div>
      <div className="Polaris-Labelled__LabelWrapper">
        <Label id={`mapping-template-textfield-${idx}Label`}>{`Set ${props.mappingKey} template`}</Label>
      </div>
      <div className="Polaris-Connected">
        <div className="Polaris-Connected__Item">
          <Select
            name={`Contentful field select for mapping template ${idx}`}
            label="Select contentful field"
            labelHidden
            options={props.options}
            onChange={props.onSelect}
            id={`mapping-select-${idx}`}
          />
        </div>
        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          <div className="Polaris-TextField" style={{ display: 'block' }}>
            <Tags
              tagifyRef={tagifyRef}
              settings={{
                mixTagsInterpolator: ['{{{', '}}}'],
                mode: 'mix',
                editTags: false,
                pasteAsTags: false,
                duplicates: true,
              }}
              name={`Mapping template ${idx}`}
              value={props.value}
              className="Polaris-TextField__Input"
              onChange={props.onChange}
            />
            <div className="Polaris-TextField__Backdrop"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
