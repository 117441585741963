import { Frame, Loading } from '@shopify/polaris'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SessionContext } from '~/components/SessionProvider'
import { ProductRule } from '~/containers/ProductRule/ProductRule'
import { Connection } from '~/containers/RuleForm/Connection'
import { RuleItem } from '~/services/dynamodb/stores'

export const ProductRulePage = () => {
  const { endpoint, initialized } = useContext(SessionContext)
  const slugArray = window.location.pathname.split('/')
  const ruleId = slugArray[slugArray.length - 1]

  const [rule, setRule] = useState<RuleItem>()

  const fetchRule = useCallback(async () => {
    if (!endpoint || !ruleId) return
    const url = new URL(`${window.location.origin}/api/stores/rules`)
    url.searchParams.append('endpoint', endpoint as string)
    url.searchParams.append('id', ruleId.split('%23')[1])
    const response = await fetch(url.toString())
    if (response.status === 200) {
      const rule = (await response.json()) as RuleItem
      setRule(rule)
    }
  }, [endpoint, ruleId])

  useEffect(() => {
    fetchRule()
  }, [fetchRule])

  if (!initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Connection
      initialValues={{
        rule_name: rule?.rule_name,
        content_model_id: rule?.content_model_id,
        shopify_ref_field: rule?.shopify_ref_field,
        mappings: rule?.mappings,
        active: rule?.active,
      }}
      webhookId={rule?.webhook_id}
    >
      <ProductRule rule={rule} />
    </Connection>
  )
}

export default ProductRulePage
