import { ClientApplication } from '@shopify/app-bridge'
import { authenticatedFetch } from '@shopify/app-bridge-utils'
import { Redirect } from '@shopify/app-bridge/actions'

export function fetch(app: ClientApplication<any>) {
  const fetchFunction = authenticatedFetch(app)

  return async (uri: RequestInfo, options?: RequestInit | undefined): Promise<Response> => {
    const response = await fetchFunction(uri, options)
    if (response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1') {
      const authUrlHeader = response.headers.get('X-Shopify-API-Request-Failure-Reauthorize-Url')

      const url = new URL(window.location.href)
      let endpoint = url.searchParams.get('shop')

      const redirect = Redirect.create(app)
      redirect.dispatch(Redirect.Action.APP, authUrlHeader || `/api/auth/session?shop=${endpoint}`)
      return response
    }

    return response
  }
}
